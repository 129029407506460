import classes from './Programs.module.css';
import Tag from '../ui/Tag';

const Programs = () => {
  return (
    <div className={classes.container}>
      <div className={classes.programs}>
        <h1 className={classes['heading']}>Our Programs</h1>
        <div className={classes['programs-content']}>
          <div className={classes['programs-current']}>
            <div className={classes['programs-current-cover']}>
              <span>CURRENT</span>
            </div>
            <div className={classes['programs-current-content']}>
              <ul>
                {/* <li>
                  <a
                    className={classes.tag}
                    href='https://drive.google.com/file/d/1T-HDbD249CpOKW7W0jJIlv0m_g_5wH7G/view?usp=sharing'
                    rel='noreferrer'
                    target='_blank'
                  >
                    Summer Camp
                  
                  </a>
                </li> */}
                <li>
                  <a
                    className={classes.tag}
                    href='https://drive.google.com/file/d/1xHvc_15ZjmYJFxPKxLNe20ERkHjzfAdG/view?usp=sharing'
                    rel='noreferrer'
                    target='_blank'
                  >
                    After School
                  </a>
                  {/* <Tag
                    toValue='/programs/afterschool'
                    text='After School'
                  ></Tag> */}
                </li>
                <li>
                  <Tag
                    toValue='/programs/currentevents'
                    text='Current Events'
                  ></Tag>
                </li>
                <li>
                  <Tag toValue='/programs/debate' text='Debate'></Tag>
                </li>
                <li>
                  <Tag toValue='/programs/reading' text='Reading'></Tag>
                </li>
                <li>
                  <Tag toValue='/programs/science' text='Science'></Tag>
                </li>
                <li>
                  <Tag toValue='/programs/spanish' text='Spanish'></Tag>
                </li>
              </ul>
              <ul>
                <li>
                  <Tag toValue='/programs/speech' text='Speech'></Tag>
                </li>
                <li>
                  <Tag
                    toValue='/programs/studyskills'
                    text='Study Skills'
                  ></Tag>
                </li>
                <li>
                  <Tag toValue='/programs/vocabulary' text='Vocabulary'></Tag>
                </li>
                <li>
                  <Tag
                    toValue='/programs/writersmark'
                    text='Writers Mark'
                  ></Tag>
                </li>
                <li>
                  <Tag toValue='/programs/writing' text='Writing'></Tag>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className={classes['programs-incoming']}>
            <div className={classes['programs-incoming-cover']}>
              <span>INCOMING</span>
            </div>
            <div className={classes['programs-incoming-content']}>
              <ul>
                <li>Camps</li>
                <li>Chinese</li>
                <li>Coding</li>
                <li>Grammar</li>
                <li>Math</li>
                <li>Sign Language</li>
                <li>Spelling</li>
                <li>Test Prep</li>
              </ul>
            </div> 
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Programs;
